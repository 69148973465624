import React, { Component } from "react"
import { Link } from "gatsby"
import { injectStripe } from "react-stripe-elements"
import { API_URL, fmtCurrency } from "../../../../utils/helpers"
import StripeInput from "../../../form/StripeInput"
import Msg from "../../../form/Msg"
import Submit from "../../../form/Submit"
import { Grid, ButtonContainer } from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

class CardGrid extends Component {
  state = {
    amt: this.props.totals.total,
    name: `${this.props.order.contact.fname} ${this.props.order.contact.lname}`,
    email: `${this.props.order.contact.email}`,
    zip: `${this.props.order.billing.zip}`,
    msg: {},
  }

  handleSubmit = async e => {
    e.preventDefault()
    this.setState({
      msg: {
        type: "working",
        text: "Working...Please wait.",
      },
    })

    try {
      const { token } = await this.props.stripe.createToken({
        name: this.state.name,
        email: this.state.email,
      })
      if (token) {
        //console.log(token);

        const url = new URL(`${API_URL}/checkoutPayment`)
        let data = new FormData()
        data.append("amt", JSON.stringify(this.state.amt))
        data.append("items", JSON.stringify(this.props.items))
        data.append("totals", JSON.stringify(this.props.totals))
        data.append("order", JSON.stringify(this.props.order))
        data.append("stripeToken", token.id || "")

        const response = await fetch(url, {
          method: "POST",
          body: data,
        })
        const json = await response.json()
        if (json && json.resp === 1) {
          this.props.success(json.transactionId)
        } else if (json && json.resp === 2) {
          // card was invalid or charge failed

          this.setState({
            msg: {
              type: "error",
              text: "An unknown error has occurred.",
            },
          })
        } else {
          this.setState({
            msg: {
              type: "error",
              text: json.text,
            },
          })
        }
      } else {
        this.setState({
          msg: {
            type: "error",
            text: "Please check your card details.",
          },
        })
      }
    } catch (error) {
      this.setState({
        msg: {
          type: "error",
          text: "Please check your card details.",
        },
      })
    }
  }

  render() {
    return (
      <form method="post" onSubmit={e => this.handleSubmit(e)}>
        <Grid>
          <p>Please enter payment for your order.</p>
          <p>We appreciate your business.</p>
          <div className="fields">
            <div className="card">
              <div>
                <StripeInput zip={this.state.zip} />
              </div>
            </div>
          </div>

          <div className="fields second">
            <div className="notes">
              <ul>
                <li>
                  Verify that your order details are correct, enter your credit
                  or debit card info and click the submit button to complete
                  your order.
                </li>

                <li>
                  Your card will be charged will charged for{" "}
                  <strong>{fmtCurrency(this.props.totals.total)}</strong>, all
                  charges will appear as Mechanical AC on your card statement.
                </li>

                <li>
                  If you have any questions, please{" "}
                  <Link to="/contact">contact us</Link> or call (561) 848-6227
                  for assistance.
                </li>
              </ul>
            </div>
          </div>

          <div className="submit">
            {this.state.msg.type && <Msg data={this.state.msg} />}

            <ButtonContainer
              className={this.state.msg.type === "working" ? "hidden" : ""}
            >
              <Submit
                name="Submit Order & Payment"
                icon={faChevronCircleRight}
              />
            </ButtonContainer>
          </div>
        </Grid>
      </form>
    )
  }
}

export default injectStripe(CardGrid)
