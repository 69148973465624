import React from "react"
import { fmtCurrency } from "../../../../utils/helpers"
import { Table } from "./styled"

const PaymentTable = ({ items, totals }) => {
  return (
    <Table className="show-on-desktop">
      <thead>
        <tr>
          <th>Qty</th>
          <th className="left">Item</th>
          <th>Price</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {items.map((item, index) => {
          return (
            <tr key={index}>
              <td className="center">
                <span>{item.qty}</span>
              </td>
              <td>
                <div>
                  <span>{item.item}</span>
                  <small>{item.subitem}</small>
                </div>
              </td>
              <td className="right">{item.price}</td>
              <td className="right">{item.subTotal}</td>
            </tr>
          )
        })}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan="3" className="right">
            Sales Tax
          </td>
          <td className="right">{fmtCurrency(totals.tax)}</td>
        </tr>
        <tr>
          <th colSpan="3" className="right">
            TOTAL
          </th>
          <th className="right">{fmtCurrency(totals.total)}</th>
        </tr>
      </tfoot>
    </Table>
  )
}

export default PaymentTable
