import styled from "styled-components"

export const Container = styled.div`
  width: 100%;
  @media screen and (max-width: 1023px) {
    width: 100%;
  }
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & label {
    padding-bottom: 5px;
    font-size: 0.9rem;
    cursor: pointer;

    & > span.error {
      color: #721c24;
    }
  }

  & .StripeElement {
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    padding: 10px 12px;
    border: 1px solid #cecece;
    border-radius: 4px;
    background-color: #fff;
    vertical-align: middle;
    outline: none;
    box-shadow: 0 1px 3px 0 #e6ebf1;
    -webkit-transition: box-shadow 150ms ease;
    transition: box-shadow 150ms ease;
    -webkit-appearance: none;
  }

  & .StripeElement--focus {
    /* box-shadow: 0 1px 3px 0 #cfd7df;*/
    border-color: #2d77b2;
  }

  & .StripeElement--invalid {
    border-color: #721c24;
  }

  & .StripeElement--webkit-autofill {
    background-color: #fefde5 !important;
  }
`
