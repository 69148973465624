import styled from "styled-components"

import {
  Main as BaseMain,
  MainContainer as BaseMainContainer,
  Grid as BaseGrid,
} from "../../../elements/Layout/styled"

export const Main = styled(BaseMain)``

export const MainContainer = styled(BaseMainContainer)`
  & > article {
    width: 100%;
  }
`

export const Grid = styled(BaseGrid)`
  width: 100%;
  grid-template-columns: 1fr;

  & > div.btns {
    margin-top: 30px;
    border: 1px solid #ddd;
    padding: 15px;
    display: flex;

    @media screen and (max-width: 767px) {
      flex-direction: column;
    }

    & > a:first-child {
      margin-right: 7.5px;

      @media screen and (max-width: 767px) {
        margin-right: 0;
      }
    }

    & > a:last-child {
      margin-left: 7.5px;

      @media screen and (max-width: 767px) {
        margin-left: 0;
        margin-top: 15px;
      }
    }
  }
`
