import React from "react"
import { Grid } from "./styled"

const AddressGrid = ({ data }) => {
  return (
    <Grid>
      <p>Contact</p>
      <p>Shipping/Install</p>
      <p>Billing</p>
      <div>
        <span>{`${data.contact.fname} ${data.contact.lname}`}</span>
        <span>{data.contact.email}</span>
        <span>{data.contact.phone}</span>
      </div>

      <div className="shipping">
        <span>{`${data.shipping.address}`}</span>
        {data.shipping.address2 && <span>{`${data.shipping.address2}`}</span>}
        <span>{`${data.shipping.city}, ${data.shipping.st} ${data.shipping.zip}`}</span>
      </div>

      <div className="billing">
        <span>{data.billing.address}</span>
        {data.billing.address2 && <span>{`${data.billing.address2}`}</span>}
        <span>{`${data.billing.city}, ${data.billing.st} ${data.billing.zip}`}</span>
      </div>
    </Grid>
  )
}

export default AddressGrid
