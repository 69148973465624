import React from "react"
import { CardElement } from "react-stripe-elements"
import { Container } from "./styled.js"

const StripeInput = ({ zip = "" }) => {
  return (
    <Container>
      <label htmlFor="cc">
        Credit or Debit Card <span className="error">*</span>
      </label>
      <CardElement value={{ postalCode: zip }} />
    </Container>
  )
}

export default StripeInput
