import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import { Elements, StripeProvider } from "react-stripe-elements"
import {
  STRIPE_KEY,
  getCart,
  getOrder,
  setCart,
  setOrder,
  fmtCurrency,
} from "../utils/helpers"
import Layout from "../elements/Layout"
import Helmet from "../elements/Helmet"
import Header from "../components/Header"
import PageHdr from "../components/PageHdr"
import Footer from "../components/Footer"
import PaymentTable from "../components/content/Payment/PaymentTable"
import AddressGrid from "../components/content/Payment/AddressGrid"
import Select from "../components/form/Select"
import CardGrid from "../components/content/Payment/CardGrid"
import FinanceGrid from "../components/content/Payment/FinanceGrid"
import ButtonLink from "../components/ButtonLink"
import { Main, MainContainer, Grid } from "../components/content/Payment/styled"

const Payment = () => {
  const [loading, setLoading] = useState(true)
  const [items, setItems] = useState([])
  const [totals, setTotals] = useState({
    tax: 0,
    total: 0,
  })
  const [order] = useState(getOrder()) /* can't use setOrder here */
  const [names, setNames] = useState({
    method: "cc",
  })

  useEffect(() => {
    let total = 0
    let newArray = []

    const array = getCart()
    array.forEach(arrayItem => {
      const subTotal = arrayItem.qty * arrayItem.price
      total = total + subTotal

      newArray.push({
        product: arrayItem.product,
        id:
          arrayItem.product === "system"
            ? arrayItem.systemId
            : arrayItem.filterId,
        qty: arrayItem.qty,
        item: arrayItem.item,
        subitem: arrayItem.subitem,
        price: fmtCurrency(arrayItem.price),
        subTotal: fmtCurrency(subTotal),
      })

      if (arrayItem.extras && arrayItem.extras.length > 0) {
        arrayItem.extras.forEach(arrayExtrasItem => {
          const subTotal = arrayItem.qty * arrayExtrasItem.price
          total = total + subTotal

          newArray.push({
            product: arrayExtrasItem.type,
            id: "",
            qty: arrayItem.qty,
            item: arrayExtrasItem.name,
            subitem:
              arrayExtrasItem.type === "thermostat"
                ? arrayExtrasItem.price > 0
                  ? "Upgraded thermostat"
                  : "Standard thermostat"
                : arrayExtrasItem.type === "promo"
                ? "Purchase of new system promo"
                : "Concierge installation service",
            price: fmtCurrency(arrayExtrasItem.price),
            subTotal: fmtCurrency(subTotal),
          })
        })
      }
    })
    setItems(newArray)
    setTotals({
      tax: 0,
      total: total,
    })
    setLoading(false)
  }, [])

  const handleUpdate = (name, value) => {
    setNames(names => ({ ...names, [name]: value ? value : "" }))
  }

  const handleSuccess = transactionId => {
    setOrder(null)
    setCart([])
    navigate("/receipt", {
      state: { transactionId },
    })
  }

  return (
    <Layout>
      <Helmet
        name="Payment"
        description="Review your order details and enter payment to complete your order."
        slug="payment"
      />
      <Header />
      <PageHdr title="Order Review & Payment" />
      <section>
        <Main>
          <MainContainer className="main-page">
            <article>
              <h2>Review your order details and enter payment.</h2>
              <Grid>
                <PaymentTable items={items} totals={totals} />

                <AddressGrid data={order} />

                <div className="btns">
                  <ButtonLink to="/cart" name="Edit Cart Items" />
                  <ButtonLink to="/address" name="Edit Contact/Address" />
                </div>
                {loading ? (
                  <div />
                ) : (
                  <div style={{ marginTop: "25px" }}>
                    <Select
                      label="Please select your payment method"
                      req="true"
                      name="method"
                      value={names.method}
                      data={[
                        { value: "", name: "--" },
                        { value: "cc", name: "Credit or Debit Card" },
                        {
                          value: "finance",
                          name: "Finance through Wells Fargo",
                        },
                      ]}
                      update={handleUpdate}
                      errors={[]}
                    />
                    {names.method === "cc" && (
                      <StripeProvider apiKey={STRIPE_KEY}>
                        <Elements>
                          <CardGrid
                            items={items}
                            totals={totals}
                            order={order}
                            success={handleSuccess}
                          />
                        </Elements>
                      </StripeProvider>
                    )}
                    {names.method === "finance" && (
                      <FinanceGrid
                        items={items}
                        totals={totals}
                        order={order}
                        success={handleSuccess}
                      />
                    )}
                  </div>
                )}
              </Grid>
            </article>
          </MainContainer>
        </Main>
      </section>
      <Footer />
    </Layout>
  )
}

export default Payment
