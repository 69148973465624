import styled from "styled-components"
import {
  Grid as BaseGrid,
  ButtonContainer as BaseButtonContainer,
} from "../../../../elements/Layout/styled"

export const Grid = styled(BaseGrid)`
  margin-top: 25px;
  grid-column-gap: 15px;

  & > p {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 12.5px 25px 0 25px;
    order: 0;
  }

  & > div.fields {
    order: 1;
  }

  & > p:nth-child(2) {
    @media screen and (max-width: 767px) {
      order: 2;
      margin-top: 0;
      padding-top: 0;
    }
  }

  & > div.fields.second {
    @media screen and (max-width: 767px) {
      order: 3;
    }
  }

  & div.card {
    border: 1px solid #ddd;
    padding: 15px 15px 25px 15px;

    & > div {
      display: flex;
      align-items: center;

      & > div:first-child {
        margin-right: 7.5px;
      }
      & > div:last-child {
        margin-left: 7.5px;
      }

      @media screen and (max-width: 1023px) {
        flex-direction: column;

        & > div:first-child {
          margin-right: 0;
        }
        & > div:last-child {
          margin-left: 0;
        }
        & > div {
          margin-bottom: 15px;
        }
      }
    }

    & > div.one {
      & > div:first-child {
        margin-right: 0;
      }
      & > div:last-child {
        margin-left: 0;
      }
    }

    & > div.top-margin {
      margin-top: 15px;

      @media screen and (max-width: 1023px) {
        margin-top: 0;
      }
    }
  }

  & div.notes {
    border: 1px solid #ddd;
    padding: 15px 15px 15px 15px;

    & > ul {
      margin: 0 15px;

      & > li {
        font-size: 0.9rem;
        line-height: 20px;
        padding-bottom: 10px;
      }

      & > li:last-child {
        padding-bottom: 0;
      }
    }
  }

  & > p:nth-child(2) {
    @media screen and (max-width: 767px) {
      margin-top: 25px;
    }
  }

  & > div.submit {
    border: 1px solid #ddd;
    margin-top: 30px;
    grid-column: 1 / span 2;

    order: 2;

    @media screen and (max-width: 767px) {
      grid-column: 1 / span 1;
      order: 4;
    }
  }
`

export const ButtonContainer = styled(BaseButtonContainer)`
  padding: 30px 25%;

  @media screen and (max-width: 767px) {
    padding: 30px;
  }
`
