import React, { useState } from "react"
import { Link } from "gatsby"
import { API_URL, fmtCurrency } from "../../../../utils/helpers"
import Msg from "../../../form/Msg"
import Submit from "../../../form/Submit"
import wfLogo from "../../../../img/logo-wellsfargo.png"
import carrierCard from "../../../../img/carrier-card.png"
import { Grid, ButtonContainer } from "./styled"
import { faChevronCircleRight } from "@fortawesome/pro-light-svg-icons"

const FinanceGrid = ({ items, totals, order, success }) => {
  const [details] = useState({
    amt: totals.total,
    name: `${order.contact.fname} ${order.contact.lname}`,
    email: `${order.contact.email}`,
    zip: `${order.billing.zip}`,
  })
  const [msg, setMsg] = useState({})

  const handleSubmit = async e => {
    e.preventDefault()
    setMsg({
      type: "working",
      text: "Working...Please wait.",
    })

    try {
      const url = new URL(`${API_URL}/checkoutPayment`)
      let data = new FormData()
      data.append("amt", JSON.stringify(details.amt))
      data.append("items", JSON.stringify(items))
      data.append("totals", JSON.stringify(totals))
      data.append("order", JSON.stringify(order))
      data.append("method", "finance")

      const response = await fetch(url, {
        method: "POST",
        body: data,
      })
      const json = await response.json()
      if (json && json.resp === 1) {
        success(json.transactionId)
      } else {
        setMsg({
          type: "error",
          text: json.text,
        })
      }
    } catch (error) {
      setMsg({
        type: "error",
        text: "An error has occurred.",
      })
    }
  }

  return (
    <form method="post" onSubmit={e => handleSubmit(e)}>
      <Grid>
        <p>Apply for Wells Fargo financing.</p>
        <p>We appreciate your business.</p>
        <div className="fields">
          <div className="card">
            <p>
              Buy now and pay over time with the Carrier® credit card issued
              with approved credit by Wells Fargo Bank, N.A.
            </p>

            <div>
              <img src={wfLogo} alt="Wells Fargo Logo" className="wf" />
              <img src={carrierCard} alt="Carrier Card" />
            </div>
          </div>
        </div>

        <div className="fields second">
          <div className="notes">
            <ul>
              <li>
                Verify that your order details are correct and click the submit
                button to complete your order.
              </li>

              <li>
                On the following page, use the link to visit Wells Fargo and
                apply for your Carrier® credit card for any amount up to your
                order total of <strong>{fmtCurrency(totals.total)}</strong>.
              </li>

              <li>
                If you have any questions, please{" "}
                <Link to="/contact">contact us</Link> or call (561) 848-6227 for
                assistance.
              </li>
            </ul>
          </div>
        </div>

        <div className="submit">
          {msg.type && <Msg data={msg} />}

          <ButtonContainer className={msg.type === "working" ? "hidden" : ""}>
            <Submit name="Submit Order" icon={faChevronCircleRight} />
          </ButtonContainer>
        </div>
      </Grid>
    </form>
  )
}

export default FinanceGrid
